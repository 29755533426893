import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {

  @Output() onNeedHelp = new EventEmitter();
  supportTitle = 'Stay positive and remember 2021 doesn\'t define the rest of your life.';
  adviceTitle = 'Motivation and advice from the experts.';
  loaded = false;
  title;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event.url.includes('advice')) {
        this.title = this.adviceTitle;
      } else {
        this.title = this.supportTitle;
      }
    });
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  }

  needHelp() {
    this.onNeedHelp.emit();
  }
}
