<div class="share-container" *ngIf="url">
  <h3 class="share-title">Share this on</h3>
  <div class="share-buttons">
    <button class="twitter" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="twitter">
      <img src="/assets/images/twitter-no-bg.svg" alt="twitter-icon">
    </button>
    <button class="messenger" (click)="shareMessenger()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="messenger">
      <img src="/assets/images/messenger-no-bg.svg" alt="messenger-icon">
    </button>
    <button class="whatsapp" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="whatsapp"><img
        src="/assets/images/whatsapp-no-bg.svg"
        alt="whatsapp-icon">
    </button>
    <button class="linkedin" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="linkedin"><img
        src="/assets/images/linkedin-no-bg.svg"
        alt="linkedin-icon">
    </button>
    <button class="facebook" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="facebook">
      <img src="/assets/images/facebook-no-bg.svg" alt="facebook-icon">
    </button>
    <button class="tumbler" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="tumblr">
      <img src="/assets/images/tumblr-no-bg.svg" alt="tumblr-icon">
    </button>
    <button class="pinterest" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="pinterest">
      <img src="/assets/images/pinterest-no-bg.svg" alt="pinterest-icon">
    </button>
    <button class="email" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="email">
      <img src="/assets/images/email-no-bg.svg" alt="email-link-icon">
    </button>
    <!--<button class="snapchat" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="snapchat">
      <img src="/assets/images/snapchat.svg" alt="snapchat-icon">
    </button>-->
    <button class="copy" (click)="share()" [url]="url"
            [description]="description"
            tags="yougotthis,drinkwise"
            shareButton="copy">
      <img src="/assets/images/share-link-no-bg.svg" alt="copy-link-icon">
      <span shareButton="copy" class="copy-link-text">Copy link</span>
    </button>
  </div>
  <div class="lines">
    <div class="yellow line"></div>
    <div class="orange line"></div>
    <div class="pink line"></div>
    <div class="orchid line"></div>
    <div class="blue line"></div>
  </div>
</div>

