import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app.component';
import {HomeComponent} from './components/home/home.component';
import {OfflineComponent} from './components/offline/offline.component';
import {HttpClientModule} from '@angular/common/http';
import {VideoPlayerComponent} from './components/home/video/player/video-player.component';
import {VideoListComponent} from './components/home/video/list/video-list.component';
import {HeaderComponent} from './components/header/header.component';
import {HomeFeatureComponent} from './components/home/feature/home-feature.component';
import {HelpComponent} from './components/help/help.component';
import {FooterComponent} from './components/footer/footer.component';
import { VideoDetailComponent } from './components/home/video/list/video-detail/video-detail.component';
import {ShareModule} from 'ngx-sharebuttons';
import { SocialShareComponent } from './components/home/video/list/social-share/social-share.component';
import {FacebookModule} from 'ngx-facebook';
import { AdviceComponent } from './components/advice/advice.component';
import {RouterModule} from '@angular/router';
import { RememberComponent } from './components/help/remember/remember.component';
import { ToggleComponent } from './components/toggle/toggle.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OfflineComponent,
    VideoPlayerComponent,
    VideoListComponent,
    HeaderComponent,
    HomeFeatureComponent,
    HelpComponent,
    FooterComponent,
    VideoDetailComponent,
    SocialShareComponent,
    AdviceComponent,
    RememberComponent,
    ToggleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ShareModule,
    FacebookModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
