<section class="remember">
  <div class="drink-wise">
    <p class="drink-wise-statement">
      Stay safe, stay healthy and remember, drinking alcohol won’t relieve stress and anxiety.
    </p>
  </div>
  <div class="yellow line"></div>
  <div class="orange line"></div>
  <div class="pink line"></div>
  <div class="orchid line"></div>
  <div class="blue line"></div>
</section>
