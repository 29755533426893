import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Video} from '../../../../../model/video';
import {FacebookService, UIParams, UIResponse} from 'ngx-facebook';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit, OnChanges {

  @Output() onShare = new EventEmitter();
  @Input() video: Video;
  @Input() url;
  @Input() title;
  @Input() description;


  constructor(private fb: FacebookService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.video) {
      this.url = this.video.url;
      this.title = this.video.description;
      this.description = 'YouGotThis Year 12';
    }
  }

  share() {
    this.onShare.emit('');
  }

  shareMessenger() {
    console.log('Sharing messenger', this.url);
    const params: UIParams = {
      method: 'send',
      link: this.url,
    };

    this.fb.ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.error(e));

    this.onShare.emit('');
  }
}
