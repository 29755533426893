import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {Video} from '../model/video';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

const vimeoUrl = 'https://vimeo.com/api/v2';
// const albumId = '7626031';
// const featuredAlbumId = '7663206';
// const adviceAlbumId = '7828368';
const albumId = '8940431';
const featuredAlbumId = '8940439';

@Injectable({providedIn: 'root'})
export class VideoService {

  private constructor(private http: HttpClient) {
  }

  getFeaturedVideos(): Observable<Video[]> {
    return this.http.get<Video[]>(`${vimeoUrl}/album/${featuredAlbumId}/videos.json`);
  }

  getAdviceVideos(): Observable<Video[]> {
    return of(adviceVideos);
  }

  getVideos(): Observable<Video[]> {
    const url = `${vimeoUrl}/album/${albumId}/videos.json`;
    return forkJoin([
      this.http.get<Video[]>(`${url}?page=1`),
      this.http.get<Video[]>(`${url}?page=2`)
    ]).pipe(map((responses) => responses[0].concat(responses[1])));
  }
}

const adviceVideos: Video[] = [
  {
    'id': 637324365,
    'title': 'Nick Molnar',
    'subtitle': 'Co-founder and CEO Afterpay',
    'description': 'Dr Andrew Rochford and Year 12\n' +
      'graduate Antonia speak to Nick\n' +
      'Molnar, CEO and co-founder of\n' +
      'Afterpay about having a great idea\n' +
      'and believing in yourself to make it\n' +
      'happen. Nick shares his advice for\n' +
      'budding young entrepreneurs,\n' +
      'dealing with ambiguity and\n' +
      'uncertainty and being a leader at\n' +
      'just 30. This is one video you don\'t\n' +
      'want to miss.',
    'url': 'https:\/\/vimeo.com\/637324365',
    'thumbnail_large': '/assets/images/thumbs/nick-molnar.png',
  }, {
    'id': 637327782,
    'title': 'Glen James',
    'subtitle': 'Host, My Millennial Money',
    'description': 'Dr Andrew Rochford\n' +
      'and Year 12 graduate Antonia\n' +
      '\n' +
      'speak to Glen James who is\n' +
      'helping young people to make\n' +
      'good financial decisions through\n' +
      'his podcast My Millennial Money.\n' +
      'How much you should be saving,\n' +
      'where to invest, credit cards and\n' +
      'planning for a secure financial\n' +
      'future, these guys tackle it all.',
    'url': 'https:\/\/vimeo.com\/637327782',
    'thumbnail_large': '/assets/images/thumbs/glen-james.png',
  }, {
    'id': 637326939,
    'title': 'Bella Safari',
    'subtitle': '23 year old University student',
    'description': 'Dr Andrew Rochford\n' +
      'and Year 12 graduate Antonia\n' +
      'discuss the importance of flexibility\n' +
      'and resilience with Bella Safari - a\n' +
      '23 year old Uni student who is\n' +
      'about to commence her third\n' +
      'University course in as many\n' +
      'years. Bella offers some practical\n' +
      'advice on transferring courses and\n' +
      'having the confidence and self-\n' +
      'assurance to change your path if\n' +
      'you know it\'s just not right for you.',
    'url': 'https:\/\/vimeo.com\/637326939',
    'thumbnail_large': '/assets/images/thumbs/bella-safari.png',
  }, {
    'id': 637325330,
    'title': 'Saxon Phipps',
    'subtitle': 'Founder Year13',
    'description': 'Dr Andrew Rochford and Year 12\n' +
      'graduate Antonia chat to Saxon\n' +
      'Phipps, the founder of Year 13 -\n' +
      'an organisation that provides 1.6\n' +
      'million recent school leavers with\n' +
      'options when it comes to work,\n' +
      'study and travel. They cover a\n' +
      'range of topics from advice on\n' +
      'finance and mental health, to\n' +
      'looking out for your mates, and\n' +
      'why Uni isn\'t the only answer.',
    'url': 'https:\/\/vimeo.com\/637325330',
    'thumbnail_large': '/assets/images/thumbs/saxon-phipps.png',
  }, {
    'id': 637326193,
    'title': 'Ashley de Silva',
    'subtitle': 'CEO ReachOut',
    'description': 'Dr Andrew Rochford and Year 12\n' +
      'graduate Antonia talk to Ashley de\n' +
      'Silva the CEO of ReachOut -\n' +
      'Australia\'s most used online\n' +
      'mental health service. After an\n' +
      'incredibly challenging 2021, many\n' +
      'young people are struggling with\n' +
      'the fear of the unknown and\n' +
      'Ashley shares some practical\n' +
      'advice for those who may not be\n' +
      'doing okay.',
    'url': 'https:\/\/vimeo.com\/637326193',
    'thumbnail_large': '/assets/images/thumbs/ashley-de-silva.png',
    comingSoon: true
  }, {
    'id': 637323527,
    'title': 'Alice Bleathman',
    'subtitle': 'Dietician & Nutritionist',
    'description': 'Dr Andrew Rochford\n' +
      'and Year 12 graduate Antonia\n' +
      'speak to Alice Bleathman - a\n' +
      'practicing dietician and nutritionist\n' +
      'who challenged the norm and\n' +
      'followed her heart and not her\n' +
      'marks. Alice shares her own\n' +
      'experience and how following your\n' +
      'passion can lead to a fulfilling and\n' +
      'rewarding career.',
    'url': 'https:\/\/vimeo.com\/637323527',
    'thumbnail_large': '/assets/images/thumbs/alice-bleathman.png',
    comingSoon: true
  }
];
