<section id="help" class="help container">
  <a id="needhelp"></a>
  <div class="help-title">
    <div class="top-pink"></div>
    <div class="top-orange"></div>
    <h1>Need immediate or ongoing help?</h1>
    <div class="bottom-orange"></div>
    <div class="bottom-pink"></div>
  </div>
  <div class="help-subtitle">
    <ul>
      <li>It's ok not to be ok, and it's ok to ask for help.</li>
      <li>
        Below are some support services who want
        to help you be your best, so please reach out if you need it.
      </li>
      <li>
        Remember too that your friends might be doing it hard,
        so let them know there's help available.
      </li>
    </ul>
  </div>

  <div class="help-sources">
    <a class="source" target="_blank" href="https://headspace.org.au">
      <div class="source-header">
        <img src="/assets/images/headspace.svg" alt="The Headspace logo">
      </div>
      <div class="source-content">
        <p> Online and in-person support for young people
        </p>
      </div>
    </a>
    <a class="source" target="_blank" href="https://au.reachout.com">
      <div class="source-header">
        <img src="/assets/images/reachout.svg" alt="The Rach Out logo">
      </div>
      <div class="source-content">
        <p> Information, tools and forums for whatever life throws at you
        </p>
      </div>
    </a>
    <a class="source" target="_blank" href="https://landing.mymirror.com.au/drinkwise?utm_source=drinkwise&utm_medium=referral&utm_campaign=yougotthis">
      <div class="source-header">
        <img src="/assets/images/mirror.svg" alt="The Mirror logo">
      </div>
      <div class="source-content">
        <p>
          Video conferencing telehealth psychology services – 21H/Day, 365D/Year
        </p>
      </div>
    </a>
    <a class="source" target="_blank" href="https://www.beyondblue.org.au">
      <div class="source-header">
        <img src="/assets/images/beyondblue.svg" alt="The Beyond Blue logo">
      </div>
      <div class="source-content">
        <p>Support for your mental health and wellbeing</p>
      </div>
    </a>
    <a class="source" target="_blank" href="https://drinkwise.org.au">
      <div class="source-header">
        <img id="drink-wise" src="/assets/images/drink-wise-green.png" alt="The DrinkWise logo">
      </div>
      <div class="source-content">
        <p>
          Education for healthier and safer drinking
        </p>
      </div>
    </a>
    <a class="source" target="_blank" href="https://kidshelpline.com.au">
      <div class="source-header">
        <img class="kids-help-line" src="/assets/images/kidshelpline.png" alt="The Kids Helpline logo">
      </div>
      <div class="source-content">
        <p>
          Free, national, 24/7 confidential support and counselling for young people aged 5 to 25 via phone, email and real-time WebChat
        </p>
      </div>
    </a>
  </div>
</section>

<!-- 2021/10/22 - Removed at the request of Lou and Mel -->
<!--<div class="reason-why">-->
<!--  <a href="/assets/yougotthis.pdf" target="_blank">-->
<!--    <h2>What the research is saying about how Year 12s are coping.</h2>-->
<!--  </a>-->
<!--</div>-->
