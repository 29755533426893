import {Component, OnInit} from '@angular/core';
import {VideoService} from '../../services/video.service';
import {finalize} from 'rxjs/operators';
import {Video} from '../../model/video';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss']
})
export class AdviceComponent implements OnInit {

  videos: Video[];
  selectedVideo: Video = null;
  loading = false;
  loadError: any;

  constructor(private videoService: VideoService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadError = null;
    this.videoService.getAdviceVideos()
      .pipe(finalize(() => this.loading = false))
      .subscribe(response => {
          this.videos = response;
        },
        error => this.loadError = error
      );
  }

  selectVideo(video: Video) {
    this.selectedVideo = video;
  }

  closeVideo() {
    this.selectedVideo = null;
  }

}
