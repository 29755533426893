<div class="page-title">

  <div class="header-link">
    <!--    <button class="help-button" (click)="needHelp()">Need help now?</button>-->
    <button [routerLink] = "['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" class="home-button">Messages of support</button>
    <button [routerLink] = "['/advice']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" class="advice-button">Advice from the experts</button>
    <button class="help-button" (click)="needHelp()">Need help now?</button>
  </div>
  <div class="page-title-content container">
    <h1 class="title">
      Class of
      <span class="orange">2</span>
      <span class="other-orange">0</span>
      <span class="pink">2</span>
      <span class="blue">1</span>
    </h1>
    <div class="logo">
      <img class="logo-blue" src="/assets/images/logo-blue.svg" alt="" aria-hidden="true" role="presentation">
      <img class="logo-purple" src="/assets/images/logo-purple.svg" alt="" aria-hidden="true" role="presentation">
      <img class="logo-pink" src="/assets/images/logo-pink.svg" alt="" aria-hidden="true" role="presentation">
      <img class="logo-orange" src="/assets/images/logo-irange.svg" alt="" aria-hidden="true" role="presentation">
      <img class="logo-yellow" src="/assets/images/logo-yellow.svg" alt="" aria-hidden="true" role="presentation">
      <img class="logo-white" src="/assets/images/logo-white.svg" alt="The You Got This">
    </div>
    <div class="drink-wise-statement">
      <h1 class="title">{{title}}</h1>
      <div class="initiative">
        <div>An initiative of</div>
        <div>
          <img src="/assets/images/drink-wise.svg" alt="DrinkWise">
        </div>
      </div>
    </div>
  </div>
  <div class="curvy-swirl">
    <!--    <img alt="Rainbow coloured swirl" src="/assets/images/top-curvy-bg-small.png">-->
    <img alt="Rainbow coloured swirl" src="/assets/images/Rainbow_wave.svg">
  </div>
</div>
