<div class="toggle-wrapper"
     [class.left-hover]="hover === 'left'"
     [class.right-hover]="hover === 'right'"
     (mouseleave)="setHover(null)"
>
  <div class="half left-half" (mouseenter)="setHover('left')"></div>
  <div class="half right-half" (mouseenter)="setHover('right')"></div>

  <div class="btn-group">

    <button [routerLink] = "['/']" class="home-button"
            (mouseenter)="setHover('left')">
      Messages<br/>of support
    </button>

    <button [routerLink] = "['/advice']"
            class="advice-button"
            (mouseenter)="setHover('right')">
      Advice from<br/>the experts
    </button>
  </div>
</div>
