import {Component, OnInit} from '@angular/core';
import {FacebookService, UIParams, UIResponse} from 'ngx-facebook';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  show = false;

  constructor(private fb: FacebookService) {
  }

  ngOnInit(): void {
  }

  showShareDropdown() {
    this.show = !this.show;
  }

  shareMessenger() {
    const params: UIParams = {
      method: 'send',
      link: 'https://yougotthis.org.au/',
    };

    this.fb.ui(params)
      .then((res: UIResponse) => console.log(res))
      .catch((e: any) => console.error(e));
  }
}
