import {Component, ElementRef, ViewChild} from '@angular/core';
import {FacebookService, InitParams} from 'ngx-facebook';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('help')
  helpElement: ElementRef;

  constructor(private fb: FacebookService) {

    const initParams: InitParams = {
      appId: '3537492456316272',
      xfbml: true,
      version: 'v2.8'
    };

    fb.init(initParams);

  }

  goToHelp() {
    this.helpElement.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
