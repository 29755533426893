<app-remember></app-remember>
<section class="social-link-container" (mouseleave)="show=false">
  <h2 class="spread-work">Spread the word</h2>
  <div class="social-links">
    <div shareButton="twitter" url="https://yougotthis.org.au/" class="link">
      <img src="/assets/images/twitter.svg" alt="twitter">
    </div>
    <div shareButton="messenger" url="https://yougotthis.org.au/" class="link" (click)="shareMessenger()">
      <img class="messenger" src="/assets/images/messenger.svg" alt="messenger">
    </div>
    <div shareButton="whatsapp" url="https://yougotthis.org.au/" class="link">
      <img src="/assets/images/whatsapp.svg" alt="whatsapp">
    </div>
    <!--
        <div class="link">
          <img class="instagram" src="/assets/images/instagram.svg" alt="instagram">
        </div>
    -->
    <div shareButton="facebook" url="https://yougotthis.org.au/" class="link">
      <img src="/assets/images/facebook.svg" alt="facebook">
    </div>
    <div (click)="showShareDropdown()" class="link">
      <img class="share" src="assets/images/share.svg" alt="share">
      <div class="share-popout" [class.showing]="show">
        <app-social-share (onShare)="showShareDropdown()" [url]="'https://yougotthis.org.au/'"></app-social-share>
      </div>
    </div>

  </div>
</section>

<section class="contacts">
  <div class="contacts-row">
    <div class="messages">
      <div class="content">
        <p class="title">#yougotthis</p>
        <p>Messages of support for Australians through Year 12</p>
      </div>
    </div>
    <div class="contact-wrapper">
      <a class="podcast"
         href="https://drinkwise.org.au/our-work/bounce-back-podcast-presented-by-drinkwise">
        <div class="content">
          <img class="podcast-logo" src="/assets/images/podcast.svg" alt="podcast">
          <p>Podcast Series</p>
        </div>
      </a>
    </div>
    <div class="contact-wrapper">
      <a class="email" href="mailto:hello@yougotthis.org.au">
        <div class="content">
          <p class="media-hint">Media enquiries and feedback</p>
          <img class="email-logo" src="/assets/images/envelope.svg" alt="envelop">
          <p>
            hello@yougotthis.org.au
          </p>
        </div>
      </a>
    </div>
  </div>
</section>

<section class="footer">
  <div class="footer-row">
    <a href="https://drinkwise.org.au/" class='drink-wise-initiative supporter' target="_blank">
      <div class="title">An initiative of</div>
      <div class="drinkwise">
        <img class="drink-wise-logo" src="/assets/images/drink-wise.svg"
             alt="DrinkWise logo">
      </div>
    </a>
    <a href="https://www.89degreeseast.com/" class="supported-by supporter" target="_blank">
      <div class="logo89de">
        <img src="/assets/images/eightyninede.png" alt="89 Degrees East logo">
      </div>
    </a>
  </div>
</section>
