<div class="featured-video-area">

  <!--  <div class="curvy-swirl">-->
  <!--    &lt;!&ndash;    <img alt="Rainbow coloured swirl" src="/assets/images/top-curvy-bg-small.png">&ndash;&gt;-->
  <!--    <img alt="Rainbow coloured swirl" src="/assets/images/Rainbow_wave.svg">-->
  <!--  </div>-->

  <div class="featured-video" (click)="playVideo()">
    <img class="thumbnail" [src]="featuredVideo?.thumbnail_large" [alt]="featuredVideo?.description"/>
    <img class="play-btn" alt="Play button" src="/assets/images/play-button.svg">
  </div>

  <div class="featured-video-desc">
    <div class="desc container">
      <h1>
        So remember when we all thought 2020 was bad?
        <p>
          Then 2021 came along. What an absolute
        <span class="orchid">$</span>
        <span class="yellow">#</span>
        <span class="orchid">!</span>
        <span class="orange">^</span>
        <span class="blue">$</span>
        <span class="pink">#</span>
        <span class="orange">@</span>
        <span class="pink">&</span>
        <span class="yellow">!</span>
        </p>
      </h1>
      <h3>
        It's not what any of us expected or wanted, but we're in it. And you need to know that one year does not define
        the rest of your life.
      </h3>
      <h3>
        You've done an incredible job so far and everyone's got your back, including these well-known Australians...
      </h3>
      <h1 class="hash-tag">
        <span class="orchid">#</span>
        <span class="blue">you</span>
        <span class="pink">got</span>
        <span class="yellow">this</span>
      </h1>
      <app-toggle></app-toggle>
    </div>
  </div>

</div>
