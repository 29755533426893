<div class="desc-container">
  <div class="desc">
    <h2 *ngIf="showText" class="video-title">{{video.title}}</h2>
    <p *ngIf="showText">
      {{video.description}}
    </p>
  </div>
  <div class="share">
    <button class="btn" (click)="toggleDescription()">
      <img alt="Show or hide sharing options" src="/assets/images/share.svg">
    </button>
  </div>
</div>
