<div class="wrapper">
  <div class="video-pane" (mouseleave)="showing=false">
    <div class="video-header">
      <div class="logo">
        <span>You got this</span>
      </div>
      <div class="actions">
        <button class="share" (click)="toggleShareDropdown()">
          <img src="/assets/images/share-no-background.svg" alt="The icon for sharing, showing whites dots connected in a network"> <span>Share</span>
        </button>
        <button class="btn btn-close" (click)="close()">
          <img src="/assets/images/close.svg" alt="A cross icon to close the video">
        </button>
      </div>
    </div>
    <div class="share-popout" [class.showing]="showing">
      <app-social-share (onShare)="toggleShareDropdown()"
                        [video]="video"></app-social-share>
    </div>
    <div #player_container></div>
  </div>
</div>



