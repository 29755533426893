import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Video} from '../../../../../model/video';

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit {

  @Input() video: Video;
  @Input() showText = true;
  @Output() onShare = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleDescription() {
    this.onShare.emit();
  }

}
