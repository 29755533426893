<div class="main-content advice">
  <div class="container content-container">

    <div class="lead-text">
      <p>
        2021 what an absolute
        <span class="orchid">$</span>
        <span class="yellow">#</span>
        <span class="orchid">!</span>
        <span class="orange">^</span>
        <span class="blue">$</span>
        <span class="pink">#</span>
        <span class="orange">@</span>
        <span class="pink">&</span>
        <span class="yellow">!</span>
      </p>
      <p>
        But the great news is it's almost over.
      </p>
      <p>
      <p>
        Now you're facing some big decisions and a lot of change after school - finding a job,
        figuring out where and what to study – charting a course for the next chapter of your life
        - it can be overwhelming.
      </p>
      <p>
        But we want to help you through this next stage with some advice from people who
        have been in a similar situation. Hopefully they can provide insight and advice for you, from
        navigating university to looking after your mental health and finances.
      </p>
    </div>


    <ng-container *ngIf="!loading; else isLoading">
      <ng-container *ngIf="!loadError; else isLoadError">

        <div class="videos" #videosSection>

          <div *ngFor="let video of videos; let i = index" class="video video-{{i+1}}">
            <div class="thumbnail-wrapper">
              <img class="thumbnail"
                   [src]="video.thumbnail_large"
                   [alt]="video.description"
                   (click)="selectVideo(video)"/>
              <img class="play-btn" alt="Play button" src="/assets/images/play-button.svg"
                   (click)="selectVideo(video)">
            </div>
            <div class="video-blurb">
              <h2 class="title">{{video.title}}</h2>
              <h2 class="subtitle">{{video.subtitle}}</h2>
              <p class="description">
                {{video.description}}
              </p>
            </div>
            <!--
                        <div class="share-popout" [class.showing]="sharingVideo?.id == video.id">
                          <app-social-share [video]="sharingVideo"></app-social-share>
                        </div>
            -->
          </div>

        </div>

        <!--
                <app-video-list [videos]="videos"
                                [showBlurb]="true"
                                (onPlayVideo)="selectVideo($event)">
                </app-video-list>
        -->

      </ng-container>
    </ng-container>
    <app-toggle></app-toggle>

  </div>
</div>

<div class="video-player" [class.showing]="!!selectedVideo">
  <app-video-player (onClose)="closeVideo()" [video]="selectedVideo"></app-video-player>
</div>

<ng-template #isLoading>
  <div class="loading">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>

<ng-template #isLoadError>
  Sorry, something went wrong loading this page. Please check your internet connection and try again, or
  contact us for help.
</ng-template>
