<div class="main-content">
  <ng-container *ngIf="!loading; else isLoading">
    <ng-container *ngIf="!loadError; else isLoadError">

      <app-home-feature [featuredVideo]="featuredVideo"
                        (onPlayVideo)="selectVideo($event)">
      </app-home-feature>

      <app-video-list [videos]="videos"
                      (onPlayVideo)="selectVideo($event)">
      </app-video-list>
    </ng-container>
  </ng-container>
</div>

<div class="video-player" [class.showing]="!!selectedVideo">
  <app-video-player (onClose)="closeVideo()" [video]="selectedVideo"></app-video-player>
</div>

<ng-template #isLoading>
  <div class="loading">
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  </div>
</ng-template>

<ng-template #isLoadError>
  Sorry, something went wrong loading this page. Please check your internet connection and try again, or
  contact us for help.
</ng-template>
