import {Component, OnInit} from '@angular/core';
import {VideoService} from '../../services/video.service';
import {finalize} from 'rxjs/operators';
import {Video} from '../../model/video';
import {forkJoin} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean;
  loadError: any;
  featuredVideo: Video;
  videos: Video[];
  selectedVideo: Video;

  constructor(private videoService: VideoService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadError = null;
    forkJoin([
      this.videoService.getFeaturedVideos(),
      this.videoService.getVideos()
    ])
      .pipe(finalize(() => this.loading = false))
      .subscribe(response => {
          this.featuredVideo = response[0]?.length ? response[0][0] : null;
          this.videos = response[1];
        },
        error => this.loadError = error
      );
  }

  selectVideo(video: Video) {
    this.selectedVideo = video;
  }

  closeVideo() {
    this.selectedVideo = null;
  }
}
