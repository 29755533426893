import {Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {Video} from '../../../../model/video';

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit, OnChanges {

  @Input() videos: Video[];
  @Input() showBlurb = false;
  @Output() onPlayVideo = new EventEmitter<Video>();

  sharingVideo: Video;

  settings = {
    top: 0,
    left: 0,
    show: false,
    width: '400px'
  };


  constructor(private  render: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // shuffleArray(this.videos);
  }

  playVideo(video: Video) {
    this.onPlayVideo.emit(video);
  }

  showShareDropdown(video) {
    this.sharingVideo = video;
  }


  hide() {
    this.sharingVideo = null;
  }


}
