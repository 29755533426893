import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  hover: 'left' | 'right';

  constructor() { }

  ngOnInit(): void {
  }

  setHover(hover) {
    this.hover = hover;
  }

}
