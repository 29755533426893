import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Video} from '../../../model/video';

@Component({
  selector: 'app-home-feature',
  templateUrl: './home-feature.component.html',
  styleUrls: ['./home-feature.component.scss']
})
export class HomeFeatureComponent implements OnInit {

  @Input() featuredVideo: Video;
  @Output() onPlayVideo = new EventEmitter<Video>();

  constructor() { }

  ngOnInit(): void {
  }

  playVideo() {
    this.onPlayVideo.emit(this.featuredVideo);
  }
}
