import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import Player from '@vimeo/player';
import {Video} from '../../../../model/video';
import {BehaviorSubject} from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() video: Video;
  @Output() onClose = new EventEmitter();
  @ViewChild('player_container')
  playerContainer: ElementRef;

  private player: Player;
  showing = false;

  private videoChanges = new BehaviorSubject<Video>(null);
  private subs = [];

  screenWidth: number;
  screenHeight: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.onResize();
    this.subs.push(this.videoChanges.subscribe((video) => {
      this.destroyPlayer();
      if (video) {
        this.playVideo(video);
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.videoChanges.next(this.video);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe);
    this.videoChanges.complete();
  }

  playVideo(video: Video) {
    let playerSettings;
    if (this.screenWidth > 800) {
      let r = video.width / video.height;
      let sw = this.screenWidth * 0.8;
      let sh = this.screenHeight * 0.8;
      let w = video.width < sw ? this.video.width : sw;
      let h = w * r;
      if (h > sh) {
        h = sh;
        w = h * r;
      }

      this.playerContainer.nativeElement.style.width = w + 'px';
      this.playerContainer.nativeElement.style.height = h + 'px';
      playerSettings = {
        id: video.id,
          responsive: false,
        autoplay: true,
        width: w,
        height: h
      }
    } else {
      playerSettings = {
        id: video.id,
        responsive: false,
        autoplay: true,
        width: this.screenWidth,
        height: this.screenHeight * 0.8,
      }
    }

    this.player = new Player(this.playerContainer.nativeElement, playerSettings);

    this.player.on('ended', () => {
      gtag('event', 'video-finished', {
        eventCategory: 'video',
        eventLabel: 'play',
        eventAction: 'end',
        eventValue: video.id
      });
      this.onClose.emit();
    });

    gtag('event', 'video-started', {
      eventCategory: 'video',
      eventLabel: 'play',
      eventAction: 'start',
      eventValue: video.id
    });
  }

  close() {
    this.onClose.emit();
  }

  destroyPlayer() {
    if (this.player) {
      this.player.destroy();
      this.playerContainer.nativeElement.innerHTML = '';
      this.player = null;
    }
  }


  toggleShareDropdown() {
    this.showing = !this.showing;
  }
}
