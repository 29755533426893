<ul>
  <li class="video-{{i+1}}"
      *ngFor="let video of videos; let i = index"
      (mouseleave)="showShareDropdown(null)">
    <div class="video-wrapper">
      <!-- Thumbnail -->
      <img class="thumbnail" [src]="video.thumbnail_large" [alt]="video.description" draggable="false"
           (click)="playVideo(video)"/>
      <!-- Play button -->
      <img class="play-btn" alt="Play button" src="/assets/images/play-button.svg" draggable="false">

      <!-- Description -->
      <div class="detail-container">
        <div class="detail-inner"> <!-- A new stacking context inside the absolutely positioned parent -->
          <div class="video-desc">
            <app-video-detail [showText]="!showBlurb" (onShare)="showShareDropdown(video)" [video]="video"></app-video-detail>
          </div>
        </div>
      </div>

      <!-- Not used? -->
      <!--
      <div class="video-blurb" *ngIf="showBlurb">
        <h2 class="title">{{video.title}}</h2>
        <h2 class="subtitle">{{video.subtitle}}</h2>
        <p>
          {{video.description}}
        </p>
      </div>
      <div class="share-popout" [class.showing]="sharingVideo?.id == video.id">
        <app-social-share [video]="sharingVideo"></app-social-share>
      </div>
      -->
    </div>
  </li>

  <!--  Adding an empty <li> here so the final photo doesn't stretch like crazy. Try removing it and see what happens!  -->
<!--  <li></li>-->
</ul>


<!--<div class="video-cell  video-cell-{{i}}" *ngFor="let video of videosToShow; let i = index" (mouseleave)="hide()">-->
<!--  <div class="video-thumb-wrapper">-->
<!--    <div class="video-thumb" (click)="playVideo(video)">-->
<!--      <img class="thumbnail" [src]="video.thumbnail_large" [alt]="video.description"/>-->
<!--      <img class="play-btn" alt="Play button" src="/assets/images/play-button.svg">-->
<!--    </div>-->
<!--    <div class="video-desc">-->
<!--      <app-video-detail (onShare)="showShareDropdown(video)" [video]="video"></app-video-detail>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="share-popout" [class.showing]="sharingVideo?.id == video.id">-->
<!--    <app-social-share [video]="sharingVideo"></app-social-share>-->
<!--  </div>-->
<!--</div>-->
